import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import { gql } from "apollo-boost";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import ReactPlaceholder from "react-placeholder";
import CSAButton from "../components/CSAButton";
import CSADescription from "../components/CSADescription";
import CSALink from "../components/CSALink";
import CenteredContainer from "../components/CenteredContainer";
import FormItem from "../components/FormItem";
import HomeImageLink from "../components/HomeImageLink";
import Notification, { NotificationProps } from "../components/Notification";
import PasswordHelper from "../components/PasswordHelper";
import {
  graphQLErrorsToParagraphs,
  isGraphQLErrorCode,
} from "../helpers/graphql";
import { CURRENT_USER } from "../queries";
import "react-placeholder/lib/reactPlaceholder.css";

const SIGN_UP = gql`
  mutation SignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $practiceName: String!
  ) {
    signUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      practiceName: $practiceName
    ) {
      redirectUrl
    }
  }
`;

interface SignUpData {
  signUp: {
    redirectUrl?: string;
  };
}

interface SignUpVariables {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  practiceName: string;
}

interface FormValues {
  email: string;
  "first-name": string;
  "last-name": string;
  "practice-name": string;
  password: string;
  "password-confirmation": string;
}

const Section = styled.section`
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    max-width: 60rem;
  }
`;

const FormNotification = FormItem.withComponent(Notification);
const Submit = FormItem.withComponent(CSAButton);

const Signup: FC = () => {
  const [notification, setNotification] = useState<NotificationProps>({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { errors, getValues, handleSubmit, register, watch } =
    useForm<FormValues>({
      mode: "onBlur",
    });

  const [signUp, { loading }] = useMutation<SignUpData, SignUpVariables>(
    SIGN_UP,
    {
      refetchQueries: [{ query: CURRENT_USER }],
    },
  );

  const onSubmit = handleSubmit(async (values) => {
    try {
      const res = await signUp({
        variables: {
          email: values.email,
          firstName: values["first-name"],
          lastName: values["last-name"],
          password: values.password,
          practiceName: values["practice-name"] ?? "",
        },
      });
      window.location.href = res.data?.signUp.redirectUrl ?? "/";
    } catch (e) {
      if (window) {
        window.scrollTo(0, 0);
      }

      if (isGraphQLErrorCode(e, "DUPLICATE_EMAIL")) {
        setNotification({
          children: (
            <p>
              This email has already been used to sign up.
              <br />
              <CSALink to="/login">Log In</CSALink>
            </p>
          ),
          type: "error",
        });
      } else {
        setNotification({
          children: <>{graphQLErrorsToParagraphs(e.graphQLErrors)}</>,
          type: "error",
        });
      }
    }
  });

  return (
    <CenteredContainer>
      <HomeImageLink />

      <FormNotification type={notification.type}>
        {notification.children}
      </FormNotification>

      <Section>
        <CenteredContainer>
          <form onSubmit={onSubmit}>
            <FormItem
              ref={register({ required: "Please enter an email" })}
              error={errors.email?.message?.toString()}
              label="Email Address (required)"
              name="email"
              placeholder="name@company.com"
              required
              type="email"
            />

            <FormItem
              ref={register({ required: "Please enter your first name" })}
              error={errors["first-name"]?.message?.toString()}
              label="First Name (required)"
              name="first-name"
              placeholder="First"
              required
              type="text"
            />

            <FormItem
              ref={register({ required: "Please enter your last name" })}
              error={errors["last-name"]?.message?.toString()}
              label="Last Name (required)"
              name="last-name"
              placeholder="Last"
              required
              type="text"
            />

            <FormItem
              ref={register}
              error={errors["practice-name"]?.message?.toString()}
              label="Practice Name"
              name="practice-name"
              placeholder="Practice Name"
              type="text"
            />

            <FormItem
              ref={register({
                required: "Please enter a password",
                validate: () =>
                  isPasswordValid || "Password needs to match the rules below",
              })}
              error={errors.password?.message?.toString()}
              label="Password (required)"
              maxLength={40}
              minLength={8}
              name="password"
              placeholder="********"
              required
              type="password"
            />

            <PasswordHelper
              password={watch("password")}
              setIsValid={setIsPasswordValid}
            />

            <FormItem
              ref={register({
                required: "Please confirm your password",
                validate: (v) =>
                  getValues("password") === v || "Passwords need to match",
              })}
              error={errors["password-confirmation"]?.message?.toString()}
              label="Password Confirmation (required)"
              maxLength={40}
              name="password-confirmation"
              placeholder="********"
              required
              type="password"
            />

            <ReactPlaceholder
              ready={!loading}
              showLoadingAnimation
              style={{ height: 50, marginBottom: 16 }}
              type="rect"
            >
              <Submit disabled={loading} type="submit">
                Sign Up
              </Submit>
            </ReactPlaceholder>
          </form>
          <CSALink to="/login">Back to Log In</CSALink>
        </CenteredContainer>
        <CSADescription />
      </Section>
    </CenteredContainer>
  );
};

export default Signup;
