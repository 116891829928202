import { useMutation } from "@apollo/react-hooks";
import styled from "@emotion/styled";
import {
  faAddressBook,
  faBook,
  faBookmark,
  faCalendarAlt,
  faCalendarWeek,
  faChartLine,
  faClipboardList,
  faCog,
  faDatabase,
  faEnvelope,
  faHome,
  faListOl,
  faMoneyBillWave,
  faNetworkWired,
  faSignOutAlt,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useLocation } from "react-router";
import { CurrentUser, LOGOUT } from "../queries";
import CSALink, { CSALinkStyles } from "./CSALink";
import PracticeSelector from "./PracticeSelector";

export interface NavProps {
  currentUser?: CurrentUser;
  className?: string;
  isAdmin?: boolean;

  logoUrl?: string;
  practiceName?: string;
}

const NavSectionHeading = styled.h2`
  font-size: 1rem;
  text-transform: uppercase;
`;

const LogoutButton = CSALinkStyles.withComponent("button");

const Logo = styled.img`
  display: block;
  height: auto;
  margin: 0 0.25rem;
  max-height: 6rem;
  max-width: 12rem;
  width: auto;
`;

const Ul = styled.ul`
  margin-bottom: 2rem;
`;

const Li = styled.li`
  margin-bottom: 0.5rem;
  display: flex;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
  width: 1.25rem !important;
`;

const AnchorLink = CSALink.withComponent("a");

const Nav: FC<NavProps> = ({
  className,
  currentUser,
  // eslint-disable-next-line react/no-unused-prop-types
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAdmin,
  logoUrl,
  practiceName,
}) => {
  const [logout] = useMutation(LOGOUT);
  const location = useLocation();
  const isOwner = currentUser?.userType === "owner";
  const hasFullAccess = isOwner || currentUser?.fullAccess;

  const onLogoutClick = async (): Promise<void> => {
    try {
      await logout();
    } finally {
      window.location.href = "/";
    }
  };

  return (
    <nav className={className}>
      {logoUrl && <Logo alt={`${practiceName} Logo`} src={logoUrl} />}
      <PracticeSelector currentUser={currentUser} />
      <section>
        <Ul>
          <Li>
            <CSALink
              selected={location.pathname === "/dashboard"}
              to="/dashboard"
            >
              <Icon icon={faHome} />
              Dashboard
            </CSALink>
          </Li>
        </Ul>
      </section>
      <section>
        <NavSectionHeading>Tools</NavSectionHeading>
        <Ul>
          {hasFullAccess && (
            <Li>
              <CSALink
                selected={location.pathname === "/analytics"}
                to="/analytics"
              >
                <Icon icon={faChartLine} />
                Your Practice Analytics
              </CSALink>
            </Li>
          )}
          {hasFullAccess && (
            <Li>
              <CSALink
                selected={location.pathname === "/marketing-budget"}
                to="/marketing-budget"
              >
                <Icon icon={faMoneyBillWave} />
                Marketing Budget
              </CSALink>
            </Li>
          )}
          <Li>
            <CSALink
              selected={location.pathname === "/indicator-scorecard"}
              to="/indicator-scorecard"
            >
              <Icon icon={faTasks} />
              Indicator Scorecard
            </CSALink>
          </Li>
          <Li>
            <CSALink
              selected={location.pathname === "/marketing-planner"}
              to="/marketing-planner"
            >
              <Icon icon={faClipboardList} />
              Marketing Planner
            </CSALink>
          </Li>
          <Li>
            <CSALink
              selected={location.pathname === "/editorial-calendar"}
              to="/editorial-calendar"
            >
              <Icon icon={faCalendarAlt} />
              Editorial Calendar
            </CSALink>
          </Li>
          <Li>
            <CSALink
              selected={location.pathname === "/events-calendar"}
              to="/events-calendar"
            >
              <Icon icon={faCalendarWeek} />
              Events Calendar
            </CSALink>
          </Li>
          <Li>
            <CSALink
              selected={location.pathname === "/new-patient-log"}
              to="/new-patient-log"
            >
              <Icon icon={faBook} />
              New Patient Log
            </CSALink>
          </Li>
          <Li>
            <CSALink
              selected={location.pathname === "/my-network"}
              to="/my-network"
            >
              <Icon icon={faListOl} />
              My Network
            </CSALink>
          </Li>
          <Li>
            <CSALink
              selected={location.pathname === "/allied-health-network"}
              to="/allied-health-network"
            >
              <Icon icon={faNetworkWired} />
              Allied Health Network
            </CSALink>
          </Li>
          <Li>
            <CSALink
              selected={location.pathname === "/referral-directory"}
              to="/referral-directory"
            >
              <Icon icon={faAddressBook} />
              The Referral Directory
            </CSALink>
          </Li>
        </Ul>
      </section>
      <section>
        <NavSectionHeading>My Profile</NavSectionHeading>
        <Ul>
          <Li>
            <CSALink
              selected={location.pathname === "/settings"}
              to="/settings"
            >
              <Icon icon={faCog} />
              Settings
            </CSALink>
          </Li>
          {isOwner && (
            <Li>
              <CSALink
                selected={location.pathname === "/participants"}
                to="/participants"
              >
                <Icon icon={faUsers} />
                Participants
              </CSALink>
            </Li>
          )}
          <Li>
            <CSALink
              selected={location.pathname === "/resources"}
              to="/resources"
            >
              <Icon icon={faBookmark} />
              Resources
            </CSALink>
          </Li>
          <Li>
            <LogoutButton onClick={onLogoutClick} type="button">
              <Icon icon={faSignOutAlt} />
              Logout
            </LogoutButton>
          </Li>
          <Li>
            <AnchorLink href="mailto:kevin@moderndeskjockey.com">
              <Icon icon={faEnvelope} />
              Email Support
            </AnchorLink>
          </Li>
          <Li>
            <CSALink to="/privacy-policy">
              <Icon icon={faDatabase} />
              Privacy Policy
            </CSALink>
          </Li>
        </Ul>
      </section>
    </nav>
  );
};

export default Nav;
