import styled from "@emotion/styled";
import React, { FC } from "react";

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  max-width: 48rem;
  text-align: center;
`;

const Iframe = styled.iframe`
  margin: auto;
`;

const P = styled.p`
  margin-top: 0;
`;

const Ul = styled.ul`
  margin: auto;
  text-align: left;
  list-style-type: disc;
`;

export interface DescriptionProps {
  className?: string;
}

const Description: FC<DescriptionProps> = ({ className }) => (
  <Aside className={className}>
    <Iframe
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      frameBorder="0"
      height="315"
      referrerPolicy="strict-origin-when-cross-origin"
      src="https://www.youtube.com/embed/zgrgCO5IRMg?si=D7L2IwAnd58ssuPC&controls=0&rel=0"
      title="YouTube video player"
      width="560"
    />
    <p>
      Imagine having a single place to track and manage your progress as a DC.
      The CSA Digital Dashboard provides you with all of the variables to make
      accurate decisions to grow your practice while all located in a secure
      online portal that is branded for your practice.
    </p>

    <P>
      The Following Dashboard Tools will help you grow and manage your practice
    </P>

    <Ul>
      <li>Practice Analytics Tracker</li>
      <li>Marketing Budget</li>
      <li>Indicator Scorecard</li>
      <li>Marketing Planner</li>
      <li>Editorial Calendar</li>
      <li>Events Calendar</li>
      <li>New Patient Log</li>
      <li>My Network Tool</li>
      <li>Allied Health Network</li>
      <li>The Referral Directory</li>
    </Ul>
  </Aside>
);

export default Description;
